import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import dogIllustration from "../images/dog-illustration.svg";
import Section from "../components/atoms/section";
import Page from "../components/atoms/page";
import Main from "../components/atoms/main";
import Locator from "../components/locator/Locator";
//import {stores} from '../data/stores'

function Locations() {

    const title = 'Locations';

    //let stores = data.allContentfulStores;

    //let count= Math.floor((stores.length/ 10)) * 10;


    //console.log('stores', stores);

    return (
        <Layout useMain={true}>
            <SEO
                title={title}
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
            />

        </Layout>
    );
}

export default Locations;

